<template>
  <div class="list">
    <list-search @onSearch="onSearch" @clearSearch="clearSearch" :childData="childData"></list-search>
    <div class="evaluation-box">
      <div class="exportData">
        <el-button class="textBtn" @click="AddRework" type="text">+新增</el-button>
      </div>
      <div class="tableList">
        <div class="table">
          <el-table :data="tableData" stripe style="width: 100%">
            <el-table-column prop="userAccount" label="用户账号" width="" :show-overflow-tooltip="true">
            </el-table-column>
            <el-table-column prop="username" label="用户昵称" :show-overflow-tooltip="true" width=""></el-table-column>
            <el-table-column prop="department" label="部门" width="" :show-overflow-tooltip="true">
            </el-table-column>
            <el-table-column prop="cpcode" label="供应商分配" width="" :show-overflow-tooltip="true">
              <template #default="scope">
                <el-button size="mini" type="text" @click="handleModelShow(scope.$index, scope.row)">
                  <div class="gist">供应商分配</div>
                </el-button>
              </template>
            </el-table-column>
            <el-table-column label="操作" width="100px">
              <template #default="scope">
                <el-button type="text" size="mini" @click="eliminateDinlog(scope.row)">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
          <div class="tablepage">
            <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :page-size = pageSize
            layout="total,  prev, pager, next, jumper"
            :total = total>
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
    <dialog-box ref="AddNewData" componentName="AddSupAllDialog" dialogWidth="650px" :dialogShow="AddcheckDialogShow" @handleClose='handleClose' title="新增用户"
    :buttonData="[{ label: '取消', action: 'handleClose', size: 'mini' },{label:'确认',action:'addPrework', type:'primary', size:'mini'}]" @addPrework="peworkBtn"></dialog-box>

    <dialog-box ref="getSubModel" componentName="AllocationDialog" dialogWidth="650px" :dialogShow="SubModelShow" :newSubModelData="newSubModelData" @handleClose='handleClose1' title="供应商分配" :buttonData="[{ label: '取消', action: 'handleClose', size: 'mini' },{label:'确认',action:'addSubModel', type:'primary', size:'mini'}]" @addSubModel="subModelBtn"></dialog-box>
  </div>
</template>

<script>
import Component from '@/assets/js/components.js'
import { SearchList, SearchData } from './js/setting.js'
import { request } from '@/assets/js/http.js'
// import qs from 'qs'
// import dayjs from 'dayjs'
export default {
  components: Component.components,
  props: ['formData', 'dataFormat'],
  name: 'SupplierAllocation',
  data () {
    var pageSize = parseInt((document.documentElement.clientHeight - 330) / 42)
    if (pageSize < 10) {
      pageSize = 10
    }
    return {
      form: {},
      newSubModelData: {},
      AddcheckDialogShow: false,
      SubModelShow: false,
      warrCheckData: [],
      childData: { searchList: SearchList, searchData: SearchData },
      pageNum: 1, // 当前第几页
      pageSize: pageSize, // 每页显示多少条
      total: 0, // 共多少条
      tableData: []
    }
  },
  created () {
    this.getDataList()
  },

  methods: {
    // 获取参评人员列表
    getDataList (data) {
      const obj = {
        ...data
      }
      request('/api/evaluation/userJurisdiction/queryAll?pageNum=' + this.pageNum + '&' + 'pageSize=' + this.pageSize, 'POST', obj).then((res) => {
        if (res.code === '200') {
          this.tableData = res.data.records
          this.total = res.data.total
        }
      })
    },

    // 新增弹窗
    AddRework () {
      this.AddcheckDialogShow = true
    },
    // 点击新增里面的确认按钮
    peworkBtn () {
      var newsArr = this.$refs.AddNewData.$refs.content.form
      if (!newsArr.userAccount) {
        this.$message({
          showClose: true,
          type: 'warning',
          message: '请输入用户账号'
        })
        return false
      }
      if (!newsArr.username) {
        this.$message({
          showClose: true,
          type: 'warning',
          message: '请输入用户名'
        })
        return false
      }
      if (!newsArr.department) {
        this.$message({
          showClose: true,
          type: 'warning',
          message: '请输入部门'
        })
        return false
      }

      const obj = {
        userAccount: newsArr.userAccount,
        username: newsArr.username,
        department: newsArr.department
      }
      request('/api/evaluation/userJurisdiction/save', 'POST', obj).then((res) => {
        if (res.code === '200') {
          this.$message({
            showClose: true,
            type: 'success',
            message: '添加成功'
          })
          this.getDataList()
        }
        this.AddcheckDialogShow = false
      })
    },

    // 供应商分配弹框
    handleModelShow (index, row) {
      this.newSubModelData = row
      this.tableDataId = row.id
      this.SubModelShow = true
    },
    // 点击供应商分配弹框里的确认按钮
    subModelBtn () {
      var newSubModel = this.$refs.getSubModel.$refs.content.tableData
      if (newSubModel) {
        const obj = [
          ...newSubModel
        ]
        request('/api/evaluation/userJurisdiction/update?id=' + this.tableDataId, 'POST', obj).then((res) => {
          if (res.code === '200') {
            this.$message({
              showClose: true,
              type: 'success',
              message: '操作成功'
            })
            this.getDataList()
          }
          this.SubModelShow = false
        })
      } else {
        this.$message({
          showClose: true,
          type: 'warning',
          message: '请添加供应商'
        })
      }
    },

    // 删除
    eliminate (row) {
      request('/api/evaluation/userJurisdiction/delete?id=' + row.id, 'post').then((res) => {
        if (res.code === '200') {
          this.$message({
            showClose: true,
            type: 'success',
            message: '删除成功'
          })
          this.getDataList()
        }
      })
    },
    // 删除弹框
    eliminateDinlog (row) {
      this.$confirm('确认要删除吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.eliminate(row)
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        })
      })
    },

    // 关闭弹窗
    handleClose () {
      this.AddcheckDialogShow = false
      this.SupdialogShow = false
    },
    handleClose1 () {
      this.SubModelShow = false
    },

    // 查询
    onSearch (data) {
      this.pageNum = 1
      this.getDataList(data)
    },
    // 清空
    clearSearch (data) {
      this.pageNum = 1
      this.getDataList(data)
    },
    // 分页
    handleSizeChange (val) {
      this.pageSize = val
      this.getDataList()
    },
    handleCurrentChange (val) {
      this.pageNum = val
      this.getDataList()
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/css/tableList.scss';
.list{
  .exportData{
    border-bottom: 1px solid #dddcdc;
    padding: 8px 0px 8px;
  }
  .textBtn{
    font-size: 16px;
  }
  .tableList{
    margin-top: -10px;
    padding: 0;
  }

  .evaluation-box{
    padding: 0 30px;
  }
  .elrowflx{
    margin-bottom: 20px;
    :deep(.el-col){
      margin-bottom: 8px;
      line-height: 34px;
    }
  }
  .modelcheck{
    margin-top: 10px;
    :deep(.el-select--small){
      width: 430px;
    }
  }
  :deep(.dialogBox .el-overlay .el-dialog .el-dialog__body){
    padding-top: 0;
  }
}
</style>
